import Logout from '@apps/shared/src/auth/Logout';
import PasswordReset from '@apps/shared/src/auth/PasswordReset';
import PasswordResetSuccess from '@apps/shared/src/auth/PasswordResetSuccess';
import SessionTimeoutManager from '@apps/shared/src/auth/AppSessionManager';
import PrivateRoute from '@apps/shared/src/auth/PrivateRoute';
import { getAuth } from '@apps/shared/src/auth/userActions';
import VerifyEmail from '@apps/shared/src/auth/VerifyEmail';
import VerifyResetPassword from '@apps/shared/src/auth/VerifyResetPassword';
import WithRedirect from '@apps/shared/src/auth/WithRedirect';
import { getMemberDetail } from '@apps/shared/src/getMember/actions';
import { getDependentMembersDetail } from '@apps/shared/src/dependentMember/actions';
import MFAPopup from '@apps/shared/src/MFA/MFA';
import AccountDisabled from '@apps/shared/src/auth/AccountDisabled';
import { ThemeProvider } from '@material-ui/core/styles';
import 'babel-polyfill'; // for all of our friends using IE
import 'es6-object-assign';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Analysis from './analysis/Analysis';
import CreateNewPassword from './auth/CreateNewPassword';
import Users from './auth/Users';
import Welcome from './auth/Welcome';
import WelcomeMember from './auth/WelcomeMember';
import Benchmarks from './benchmarks/Benchmarks';
import CodeResearch from './codeResearch/CodeResearch';
import BenchmarksTraining from './documentation/BenchmarksTraining';
import InstructionalVideo from './documentation/InstructionalVideo';
import PrivacyPolicy from './documentation/PrivacyPolicy';
import ProviderSearchFAQ from './documentation/ProviderSearchFAQ';
import FAQ from './documentation/FAQ';
import ProviderSearchTraining from './documentation/ProviderSearchTraining';
import Support from './documentation/Support';
import ContactUs from './contactUs/ContactUs';
import CreateProfile from './auth/CreateProfile';
import LandingPage from './landingPage/LandingPage';
import RequestAccess from './landingPage/RequestAccess';
import ProviderSearch from './providerSearch/ProviderSearch';
import CheckEmail from './register/CheckEmail';
import Register from './register/Register';
import Reports from './reports/Reports';
import AppRedirectPage from './shared/AppRedirectPage';
import { roles } from './shared/globals';
import SnackbarManager from './shared/SnackbarManager';
import theme from './shared/theme';
import ChangePassword from './auth/ChangePassword';
import PasswordNotifyModal from './landingPage/PasswordExpireModal';
import Login from './auth/Login';
import MyCases from './myCases/myCases';
import MemberDetail from './caseSubmission/memberDetail';
import UploadFiles from './caseSubmission/uploadFiles';
import AccountSettings from './accountSettings/AccountSettings';
import DependentInformation from './accountSettings/DependentInformation';
import AddPlan from './accountSettings/AddPlan';
import ProviderDetail from './caseSubmission/providerDetail';
import Privacy from './privacyPolicy/privacy';
import Features from './landingPage/Features';
import Notifications from './notifications/Notifications';

const mapStateToProps = state => {
  return {
    isPasswordExpired: state.user.currentUser?.isPasswordExpired || false,
    isLoggedIn: state.user.isLoggedIn || false,
    isMFANeeded: state.user.currentUser?.isMFANeeded || false,
    isAccountDeactivated: state.user.currentUser?.isAccountDeactivated || false,
    accessLevel: state.user.accessLevel || '',
    isCaseMember: state.user.isCaseMember,
  };
};
export class App extends React.Component {
  UNSAFE_componentWillMount() {
    let csrfToken;
    const search = queryString.parse(window.location.search);
    if (search.csrfToken) {
      csrfToken = decodeURIComponent(search.csrfToken);
      localStorage.setItem('csrfToken', csrfToken);
    }
    this.props.dispatch(getAuth(csrfToken));
  }

  componentDidUpdate(prevProps) {
    const { isLoggedIn, dispatch, accessLevel, isCaseMember, isMFANeeded } = this.props;
    if (
      isLoggedIn &&
      !isMFANeeded &&
      (isLoggedIn !== prevProps.isLoggedIn || isMFANeeded !== prevProps.isMFANeeded) &&
      isCaseMember &&
      accessLevel !== 'Limited Access'
    ) {
      dispatch(getMemberDetail());
      dispatch(getDependentMembersDetail());
    }
  }

  render() {
    const reload = () => window.location.reload();

    if (this.props.isAccountDeactivated) {
      return (
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <WithRedirect>
              <AccountDisabled isAccountDisabled={this.props.isAccountDeactivated} />
              <SessionTimeoutManager />
              <Switch>
                <Route exact path="/" component={LandingPage} />
                <Route path="/login" component={Login} />
                <Route path="/logout" component={Logout} />
              </Switch>
            </WithRedirect>
          </BrowserRouter>
          <SnackbarManager />
        </ThemeProvider>
      );
    }

    if (this.props.isPasswordExpired) {
      return (
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <WithRedirect>
              <PasswordNotifyModal />
              <Switch>
                <Route exact path="/" component={LandingPage} />
                <Route path="/login" component={Login} />
                <Route path="/logout" component={Logout} />
                <PrivateRoute path="/change-password" component={ChangePassword} />
              </Switch>
            </WithRedirect>
          </BrowserRouter>
          <SnackbarManager />
        </ThemeProvider>
      );
    }

    if (this.props.isMFANeeded) {
      return (
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <WithRedirect>
              <MFAPopup isMFANeeded={this.props.isMFANeeded} application="medivi" />
              <SessionTimeoutManager />
              <Switch>
                <Route exact path="/" component={LandingPage} />
                <Route path="/login" component={Login} />
                <Route path="/logout" component={Logout} />
              </Switch>
            </WithRedirect>
          </BrowserRouter>
          <SnackbarManager />
        </ThemeProvider>
      );
    }

    return (
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <WithRedirect>
            <SessionTimeoutManager />
            <Switch>
              <Route exact path="/" component={LandingPage} />
              <Route path="/login" component={Login} />
              <Route path="/logout" component={Logout} />
              <Route path="/password-reset" component={PasswordReset} />
              <Route path="/password-reset-success" component={PasswordResetSuccess} />
              <Route path="/app" component={AppRedirectPage} />
              <Route path="/privacy" component={PrivacyPolicy} />
              <Route path="/privacy.pdf" render={reload} />
              <Route path="/support" component={Support} />
              <Route path="/support.pdf" render={reload} />
              <Route path="/FAQ" component={FAQ} />
              <Route path="/contact-us" component={ContactUs} />
              <Route path="/features" component={Features} />
              <Route path="/request-access" component={RequestAccess} />
              <Route path="/privacy-policy" component={Privacy} />
              <PrivateRoute path="/my-cases" component={MyCases} />
              <PrivateRoute path="/notifications" component={Notifications} />
              <PrivateRoute path="/member-detail" component={MemberDetail} />
              <PrivateRoute path="/upload-file" component={UploadFiles} />
              <PrivateRoute path="/provider-detail" component={ProviderDetail} />
              <PrivateRoute path="/change-password" component={ChangePassword} />
              <PrivateRoute path="/instructional-video" component={InstructionalVideo} />
              <PrivateRoute path="/my-account" component={AccountSettings} />
              <PrivateRoute path="/add-plan" component={AddPlan} />
              <PrivateRoute path="/dependent-information" component={DependentInformation} />
              <PrivateRoute
                path="/benchmarks"
                component={Benchmarks}
                requiredRole={roles.benchmarks}
              />
              <PrivateRoute
                path="/benchappbenchmark"
                component={Benchmarks}
                requiredRole={roles.benchmarks}
              />
              <PrivateRoute
                path="/benchappcounty"
                component={Benchmarks}
                requiredRole={roles.benchmarks}
              />
              <PrivateRoute
                path="/benchapphospital"
                component={Benchmarks}
                requiredRole={roles.benchmarks}
              />
              <PrivateRoute
                path="/benchappbenchmarkoph"
                component={Benchmarks}
                requiredRole={roles.benchmarks}
              />
              <PrivateRoute path="/analysis" component={Analysis} requiredRole={roles.analysis} />
              <PrivateRoute path="/code" component={CodeResearch} requiredRole={roles.code} />
              <PrivateRoute path="/cms" component={CodeResearch} requiredRole={roles.code} />
              <PrivateRoute
                path="/users"
                component={Users}
                requiredRoles={[roles.admin, roles.invite]}
              />
              <PrivateRoute path="/welcome" component={Welcome} requiredRole={roles.read} />
              {/* Legacy route to "/training" */}
              <PrivateRoute
                path="/training"
                component={BenchmarksTraining}
                requiredRole={roles.read}
              />
              {/* Legacy route to "/documentation/training" */}
              <PrivateRoute
                path="/documentation/training"
                component={BenchmarksTraining}
                requiredRole={roles.read}
              />
              <PrivateRoute
                path="/documentation/benchmarks-training"
                component={BenchmarksTraining}
                requiredRole={roles.read}
              />
              <PrivateRoute
                path="/documentation/provider-search-training"
                component={ProviderSearchTraining}
                requiredRole={roles.mobileRead}
              />
              <PrivateRoute
                path="/documentation/provider-search-faq"
                component={ProviderSearchFAQ}
                requiredRole={roles.mobileRead}
              />
              <PrivateRoute path="/reports" component={Reports} requiredRole={roles.reports} />
              <PrivateRoute
                path="/provider-search"
                component={ProviderSearch}
                requiredRole={roles.mobileRead}
              />
              <Route path="/welcome-member" component={WelcomeMember} requiredRole={roles.mobile} />
              <Route path="/verify-email/:code?" component={VerifyEmail} />
              <Route path="/register" component={Register} />
              <Route path="/check-email" component={CheckEmail} />
              <Route path="/verify-reset-password/:code?" component={VerifyResetPassword} />
              <Route path="/update-password" component={CreateNewPassword} />
              <Route path="/create-profile" component={CreateProfile} />
            </Switch>
          </WithRedirect>
        </BrowserRouter>
        <SnackbarManager />
      </ThemeProvider>
    );
  }
}

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  isPasswordExpired: PropTypes.bool.isRequired,
  isMFANeeded: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(App);
