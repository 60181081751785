// Importing necessary React and Material-UI components and styles
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import MailIcon from '@material-ui/icons/Mail';

import { Case } from '@apps/shared/src/caseSelect/types/myCase';
import { CaseFilter, getMyCases, resetMyCasesState } from '@apps/shared/src/caseSelect/actions';

import MyCasesFilter from './myCasesFilter';
import AppLayout from '../shared/AppLayout';
import PageSpecificHeader from '../shared/PageSpecificHeader';
import CaseDetailModal from './caseDetailModal';
import TypeSelectionModal from '../caseSubmission/typeSelectionModal';
import { RootState } from '../store';

// Creating the Material-UI styles using makeStyles
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spinner: {
      width: '100%',
      marginTop: '2rem',
    },
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      // borderBottom: '1px solid #ECECEC',
    },
    recentCase: {
      fontSize: '28px',
      fontWeight: 600,
      lineHeight: '1.5rem',
      letterSpacing: '0px',
      textAlign: 'left',
      color: '#36383D',
    },
    subtitle: {
      fontSize: '20px',
      fontWeight: 400,
      lineHeight: '40px',
      letterSpacing: '0px',
      textAlign: 'left',
      color: '#797C80',
    },
    submitCaseBtn: {
      margin: '1.4rem 0 0 1rem',
      backgroundColor: '#F07037',
      color: '#EFECE7',
      height: '40px',
      fontSize: '16px',
      fontWeight: 700,
      marginRight: '20px',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: '#F07037',
      },
    },
    paper: {
      position: 'relative',
      border: '1px solid #E8E8E8',
      borderRadius: '8px',
      width: '75%',
      height: 'calc(100vh - 70px - 50px - 7rem)',
      '&::-webkit-scrollbar': {
        width: '1px',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '1px',
      },
    },
    contentHeight: {
      height: '90%',
      '&::-webkit-scrollbar': {
        width: '1px',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '1px',
      },
    },
    selectBox: {
      height: '10%',
      position: 'sticky',
      width: 'auto',
      borderTop: '1px solid #ECECEC',
      backgroundColor: '#fff',
      borderRadius: '0 0 .5rem .5rem',
      bottom: '0',
      right: '2.5rem',
      overflow: 'hidden',
      '& .MuiSelect-selectMenu': {
        backgroundColor: 'white !important',
        width: 'auto',
        '&:focus': {
          border: 'none',
        },
      },
      '& .MuiTablePagination-toolbar': {
        minHeight: '.5rem',
      },
    },

    headerTable: {
      position: 'sticky',
      top: 0,
      backgroundColor: '#fff',
    },
    filterAndTable: {
      display: 'flex',
      gap: '2rem',
      paddingRight: '1.2rem',
    },
    tableHead: {
      borderRadius: '0.5rem',
      height: '40px',
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '16px',
      letterSpacing: '0.04em',
      textAlign: 'left',
      color: '#333',
    },
    statusCenter: {
      paddingLeft: '3.8rem',
    },
    status: {
      width: '7.5rem',
      height: '32px',
      borderRadius: '1rem',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '18px',
      padding: '1px 10px 1px 10px',
      textTransform: 'uppercase',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    customTooltip: {
      backgroundColor: '#ffffff',
      color: '#5f5f5f',
      boxShadow:
        '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
      border: 'none',
      fontSize: '14px',
    },

    blue: {
      backgroundColor: '#E4EEFF',
      color: '#366BED',
    },

    grey: {
      backgroundColor: '#E8E8E8',
      color: '#787373',
    },

    expandDownIcon: {
      width: '24px',
      height: '24px',
      color: '#F97140',
      fontSize: '18px',
      fontWeight: 800,
      cursor: 'pointer',
    },
  })
);

type StateProps = {
  cases: Case[];
  hasLoadedCases: boolean;
  isCasesLoading: boolean;
};

const mapStateToProps = ({ caseSelect }: RootState): StateProps => ({
  cases: caseSelect.Cases,
  hasLoadedCases: caseSelect.hasLoadedCases,
  isCasesLoading: caseSelect.isCasesLoading,
});

type DispatchProps = {
  getMyCases: (filter: CaseFilter) => void;
  resetMyCasesState: () => void;
};

const mapDispatchToProps = {
  getMyCases,
  resetMyCasesState,
};

type Props = StateProps & DispatchProps;

type HeadCell = {
  label: string;
  id: keyof Case | string; // Updated to allow for nested properties
  numeric: boolean;
};

const headCells: HeadCell[] = [
  { id: 'Hospital__r', numeric: false, label: 'PROVIDER NAME' },
  { id: 'RecordType', numeric: false, label: 'TYPE OF ISSUE' },
  { id: 'CaseNumber', numeric: false, label: 'CASE ID' },
  { id: 'CreatedDate', numeric: false, label: 'CREATED ON' },
  { id: 'Status', numeric: false, label: 'STATUS' },
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (orderBy === 'Hospital__r' || orderBy === 'RecordType') {
    const aValue = a[orderBy].Name;
    const bValue = b[orderBy].Name;
    // If both values are undefined, consider them equal
    if (aValue === undefined && bValue === undefined) {
      return 0;
    }

    // If only one value is undefined, treat it as greater than the other
    if (aValue === undefined) {
      return -1;
    }
    if (bValue === undefined) {
      return 1;
    }
    if (b[orderBy].Name < a[orderBy].Name) {
      return -1;
    }
    if (b[orderBy].Name > a[orderBy].Name) {
      return 1;
    }
    return 0;
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: 'asc' | 'desc',
  orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: Case[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

type EnhancedTableProps = {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Case) => void;
  order: 'asc' | 'desc';
  orderBy: keyof Case | '';
};

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof Case) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow className={classes.headerTable}>
        <TableCell style={{ textAlign: 'center', height: '40px', borderRadius: '.5rem' }}>
          #
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align="left"
            className={`${classes.tableHead} ${
              headCell.id === 'Status' ? classes.statusCenter : ''
            }`}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id as keyof Case)} // Cast to keyof Case
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

// Defining the main functional component for MyCases
const MyCases = ({
  cases,
  getMyCases,
  hasLoadedCases,
  isCasesLoading,
  resetMyCasesState,
}: Props) => {
  const classes = useStyles();
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<keyof Case>('CaseNumber');
  const [caseDetailModalOpen, setCaseDetailModalOpen] = useState(false);
  const [caseSubmissionModalOpen, setCaseSubmissionModalOpen] = useState(false);
  const [selectedCase, setSelectedCase] = useState('');
  const [appliedFilter, setAppliedFilter] = useState<CaseFilter>({
    queryParam: '',
    type: 'All',
    createdDate: '',
    status: 'All',
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Case) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Function to sort cases based on the currently selected criteria
  const sortedCases = stableSort(cases, getComparator(order, orderBy));

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openCaseDetailModal = (id: string) => {
    setSelectedCase(id);
    setCaseDetailModalOpen(true);
  };

  const handleEmailClick = (e: any, email: string, caseNumber: string, recordType: string) => {
    e.stopPropagation();
    const emailToSend = email || '';
    const subject = `${recordType} - ${caseNumber}`;

    const mailtoLink = `mailto:${emailToSend}?subject=${encodeURIComponent(subject)}`;

    window.location.href = mailtoLink;
  };

  const handleCaseSubmissionModal = () => {
    setCaseSubmissionModalOpen(true);
  };

  const getStatusLabel = (status: string) => {
    let caseStatus;

    if (status === 'Intake' || status === 'Opened') {
      caseStatus = 'Opened';
    } else if (status === 'Closed') {
      caseStatus = 'Closed';
    } else {
      caseStatus = 'Processing';
    }
    return caseStatus;
  };

  const formatDate = (inputDate: any) => {
    if (inputDate !== '') {
      const dateObject = new Date(inputDate);
      const datePart = dateObject.toISOString().split('T')[0];
      const dateComponents = datePart.split('-');
      if (dateComponents.length === 3) {
        const year = dateComponents[0];
        const month = dateComponents[1];
        const day = dateComponents[2];
        return `${month}/${day}/${year}`;
      }
    }
    return '';
  };

  useEffect(() => {
    // if (!isCasesLoading && !hasLoadedCases) {
    getMyCases({ queryParam: '', type: '', createdDate: '', status: '' });
    // }
  }, [getMyCases]);

  return (
    <AppLayout>
      {caseSubmissionModalOpen && (
        <TypeSelectionModal
          caseSubmissionModalOpen={caseSubmissionModalOpen}
          setCaseSubmissionModalOpen={setCaseSubmissionModalOpen}
        />
      )}
      <div className={classes.container}>
        <PageSpecificHeader
          title="Recent Cases"
          description="View recent cases and submit a new case"
        />
        <Button
          variant="contained"
          className={classes.submitCaseBtn}
          onClick={handleCaseSubmissionModal}
        >
          SUBMIT A CASE
        </Button>
      </div>
      <div className={classes.filterAndTable}>
        <MyCasesFilter
          getMyCases={getMyCases}
          resetMyCasesState={resetMyCasesState}
          appliedFilter={appliedFilter}
          setAppliedFilter={setAppliedFilter}
        />
        <Paper className={classes.paper}>
          <TableContainer className={classes.contentHeight}>
            <Table>
              <EnhancedTableHead
                classes={classes}
                onRequestSort={handleRequestSort}
                order={order}
                orderBy={orderBy}
              />
              <TableBody>
                {isCasesLoading && (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      <CircularProgress
                        variant="determinate"
                        value={75}
                        className={classes.spinner}
                      />
                    </TableCell>
                  </TableRow>
                )}
                {!isCasesLoading && (cases === undefined || cases.length === 0) && (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      <Typography>No Cases Found</Typography>
                    </TableCell>
                  </TableRow>
                )}
                {!isCasesLoading &&
                  sortedCases &&
                  sortedCases.length > 0 &&
                  sortedCases
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((myCase: any, index: number) => (
                      <TableRow
                        key={myCase.id}
                        onClick={() => openCaseDetailModal(myCase.id)}
                        style={{ cursor: 'pointer' }}
                      >
                        <TableCell
                          style={{ fontSize: '14px', textAlign: 'center', fontWeight: 600 }}
                        >
                          {page * rowsPerPage + index + 1}
                        </TableCell>
                        <TableCell style={{ fontSize: '14px', fontWeight: 600 }}>
                          {myCase.Hospital__r.Name}
                        </TableCell>
                        <TableCell>
                          {(() => {
                            if (myCase.RecordType.Name === '6DH Employee Balance Bill') {
                              return 'Balance Bill';
                            }
                            if (myCase.RecordType.Name === '6DH Employee Access Issue') {
                              return 'Access Issue';
                            }
                            if (myCase.RecordType.Name === '6DH Employee Call Ahead') {
                              return 'Call Ahead';
                            }
                            return myCase.RecordType.Name;
                          })()}
                        </TableCell>
                        <TableCell>{myCase.CaseNumber}</TableCell>
                        <TableCell>{formatDate(myCase.CreatedDate)}</TableCell>
                        <TableCell>
                          <Tooltip
                            title={
                              myCase.Status_Description__c || 'Waiting for additional Information'
                            }
                            classes={{
                              tooltip: classes.customTooltip,
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'start',
                                alignItems: 'center',
                                gap: '16px',
                              }}
                            >
                              <div
                                className={`${classes.status} ${
                                  getStatusLabel(myCase.Status) === 'Processing' ||
                                  getStatusLabel(myCase.Status) === 'Opened'
                                    ? classes.blue
                                    : classes.grey
                                }`}
                              >
                                {getStatusLabel(myCase.Status)}
                              </div>
                              <MailIcon
                                className={classes.expandDownIcon}
                                onClick={e =>
                                  handleEmailClick(
                                    e,
                                    myCase?.PSS__r?.Email,
                                    myCase?.CaseNumber,
                                    myCase?.RecordType.Name
                                  )
                                }
                              />
                            </div>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={cases.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className={classes.selectBox}
          />
        </Paper>
      </div>
      <CaseDetailModal
        caseDetailModalOpen={caseDetailModalOpen}
        setCaseDetailModalOpen={setCaseDetailModalOpen}
        selectedCase={selectedCase}
      />
    </AppLayout>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MyCases);
